.ImageToolbar {
  display: flex;
  flex-wrap: wrap;
}

/*.ImageToolbar:after, .ImageToolbar:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ImageToolbar:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.ImageToolbar:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}*/
