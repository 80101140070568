.DropDownListItem {
    line-height: normal;
    display: flex;
    cursor: pointer;
    border-top: 1px solid #d3d9e0;
    padding: 12px 24px 0;

    &-Content {
        border-top: none;
        padding: 0 24px 6px;
        flex-direction: column;
        align-self: flex-start;
    }

    &-AvatarBox {
        flex-direction: column;
        align-self: flex-start;
    }

    &-Title {
        font-weight: bold;
    }

    &:hover {
        background-color: #f7f7f7;
        color: #000000;
    }

}