@import 'utils/src/global.scss';

.ContextMenu {
    text-align: left;
    position: relative;

    // transition: all .2s ease-in-out;

    &_open {}


    &-BottomLine {
        border-bottom: 1px solid #d3d9e0;
        margin: 10px;
    }

    &-Toggle {
        width: 24px;
        height: 24px;
        padding: 0;
        font-size: 14px;
        margin-left: 8px;
        line-height: 16px;

        &_open {}
    }

    &-List {
        padding: 0;
        background-color: #fff;
        overflow: hidden;
        z-index: 20;
        border-radius: 8px;
        line-height: normal;
        white-space: nowrap;
    }

    &-Item {

        .Button {
            padding: 5px 10px;
            display: block;
            width: 100%;
            text-align: left;
        }

        &:hover {
            background-color: $SystemBlue;
            color: #fff;
            .Button {
                color: #fff;
            }
        }
    }

}