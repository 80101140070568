@import 'src/utils-old/global.scss';

.UserMention {
    position: relative;
    display: inline-block;

    &-Link {
        font-weight: bold;
        color: var(--main-color);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &-Popup {
        border-radius: 4px;
        display: flex;
        align-items: flex-start;
        color: var(--text-color);
        line-height: 14px;
        min-width: 500px;
        width: auto;
        padding: 8px;
    }

    &-Contact,
    &-Name,
    &-Network,
    &-Position,
    &-Division {
        margin-bottom: 8px;
        margin-top: 0;
        word-break: normal;
    }

    &-Contact {
        white-space: wrap;
        .UserMention-CopyBTN {
            opacity: 0;
        }
    }

    &-Contact:hover {
        .UserMention-CopyBTN {
            opacity: 1;
        }
    }

    &-ContactName {
        display: inline-block;
        width: 180px;
        font-weight: bold;
        margin-right: 4px;
        &::first-letter {
            text-transform: uppercase;
        }
        &-HideInput {
            opacity: 0;
            position: absolute;
            z-index: -10;
        }
    }

    &-Avatar {
        margin-right: 15px;
        text-decoration: none;

        &:hover + .UserMention-Info > .UserMention-Name {
            text-decoration: underline;
        }
    }

    &-Info {
        flex-grow: 1;
    }

    &-NetworkIcon {
        margin-right: 6px;
    }

    &-Name {
        display: block;
        font-size: 16px;
        text-decoration: none;
        font-weight: bold;
        color: var(--text-color);
    }
}
