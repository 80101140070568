@import 'src/utils-old/global.scss';

/* autoprefixer grid: autoplace */

.RatingWithPosition {
  &-Item {
    // display: grid;
    // grid-template-areas:
    // 'pos avatar name'
    // 'pos avatar value'
    // 'pos avatar progress';
    // grid-template-columns: 1.5em 56px 1fr;
    // grid-column-gap: 12px;
    // grid-row-gap: 4px;
    align-items: center;
    padding: 15px;
    display: flex;

    >*:nth-child(n + 2) {
      margin-left: 15px;
    }

    // border-bottom: 1px solid var(--border-color);
    // background-color: var(--background-color);
    // &:last-child {
    //   border-bottom: none;
    // }
    &:hover {
      @include HoverShadow;
      opacity: 0.99;
    }

    &_leveled {
      padding-left: 50px;
    }

    &.MyItem {
      position: sticky;
      bottom: 0;
      background-color: #ffffff;
      z-index: 20;
      border-bottom: 1px solid var(--border-color);
      animation-duration: 0.2s;
      animation-name: slidein;
    }

    @keyframes slidein {
      from {
        transform: translateY(100%);
      }

      to {
        transform: translateY(0);
      }
    }

    &.myInTop {
      top: 56px;
      position: sticky;
      bottom: auto;
      animation-name: slideout;
    }

    @keyframes slideout {
      from {
        transform: translateY(-100%);
      }

      to {
        transform: translateY(0);
      }
    }

    @media (min-width: 600px) {
      // grid-template-areas: 'pos avatar name progress value';
      // grid-template-columns: 1.5em 56px 150px 1fr auto;
      // grid-gap: 16px;
      font-size: 16px;

      &Value {
        justify-self: end;
      }
    }
  }

  &-ItemPosition {
    color: #808080;
    // grid-area: pos;
    justify-self: end;
    min-width: 28px;
  }

  &-ProgressSlider {
    display: none;
    position: absolute;
    top: 0;
    width: 64px;
    transition: all 1s ease-in-out;

    svg {
      display: block;
    }

    img {
      display: block;
      max-width: 96px;
      max-height: 96px;
    }

    @media (min-width: 500px) {
      display: block;
    }
  }

  &-Progress {
    flex-grow: 2;
    position: relative;
    // grid-area: progress;
  }

  &-ProgressBox {
    text-align: right;
    margin-bottom: 20px;
    flex-basis: 100%;
    margin-left: 30px;
  }

  &-ValueBox {
    display: flex;
    justify-content: space-between;
  }

  &-UserName {
    // overflow: hidden;
    // word-spacing: 9999px;
    // text-overflow: ellipsis;
    // line-height: 1.5;
    // text-decoration: none;
    // color: $SystemBlue;
    // flex-basis: 150px;
    // background-color: #fff;
    // z-index: 1;
    // margin-right: 24px;
    // grid-area: name;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-UserAvatar {
    // grid-area: avatar;
  }

  &-ItemValue {
    position: relative;
    z-index: 10;
    text-shadow: 0px 0px 3px #fff;
    color: var(--highlight-color);
    font-weight: 700;
    display: inline-block;
    background: rgba(255,255,255, .8);
    border-radius: 20px;
    padding: 0 6px;
  }
}