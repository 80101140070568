.AudioPlayer-PopupListItem {
    display: flex;
    line-height: normal;
    padding: 0;
    width: 100%;
}

.AudioPlayer-PopupListItem-ProgressBox {
    width: 100%;
    /*250px;*/
    height: 100%;
    position: relative;
    border-left: 1px solid #d3d9e0;
}

.AudioPlayer-PopupListItem-Progress {
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    border-radius: 0;
    background-color: transparent;

    .Slider-Progress {
        background-color: #D3D9E0;
    }
}

.AudioPlayer-PopupListItem-Duration,
.AudioPlayer-PopupListItem-CurrentTime {
    width: 40px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    right: 13px;
    top: 10px;
    color: #94A4B5;
}

.AudioPlayer-PopupListItem-Button-Pause {
    background-color: #0066CC;
    color: #fff;
}

.AudioPlayer-PopupListItem-Button-Play {}

.AudioPlayer-PopupListItem-DataBox {
    display: flex;
    align-items: center;
    width: 100%;
}

.AudioPlayer-PopupListItem-TrackName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    left: 8px;
    top: 10px;
    width: 70%;
    z-index: 1;
    pointer-events: none;
}


.AudioPlayer-PopupListItem-ToggleButton {
    width: 40px;
    height: 40px;
}