@import 'src/utils-old/global.scss';

.Selectize {
  &-TooltipSpan {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  &-buttonContent {
    display: flex;
  }

  &-Name {
    margin: 0px;
  }

  &-Name,
  &-Button {
    padding: 12px;
    padding-left: 24px;
  }

  &-Avatar {
    margin-right: 12px;
  }

  &-Title {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0
  }

  &-Button {
    display: flex;
    width: 100%;
    text-align: left;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    align-items: center;

    &:hover:not(.Selectize-Button_active) {
      background-color: var(--main-color);
      color: var(--invariant-text-color);
    }

    &_active {
      background-color: #6fb19d;
      color: #fff;
    }

    &:hover:not(.Selectize-Button_active):last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &_active:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &-sortBTN {
    color: #fff;
  }

  &-Icon {
    &_hidden {
      visibility: hidden;
    }

    &_side_left {
      margin-right: 4px;
    }

    &_side_right {
      margin-left: auto;
      // color: black;
    }

    &-circle {
      height: 14px;
      width: 14px;
      min-width: 14px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  &-ShowMore {
    text-align: center;
  }

}