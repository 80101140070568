@import "src/utils-old/global.scss";

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .SplitButton {
        display: inline-block;

        &-Toggle {
            min-width: 44px;
        }
    }
}
