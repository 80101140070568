.BadgeTooltip {
    display: flex;
    align-items: flex-start;
    min-width: 250px;
    max-width: 350px;

    &-Data {
        padding-left: 12px;
    }

    &-Name {
        font-weight: bold;
    }

    &-Description {

    }
}