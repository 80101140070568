.Masonry {
  display: flex;

  &-Column {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    &:not(:last-child) {
      margin-right: 2%;
    }
  }
}