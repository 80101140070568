@import 'src/utils-old/global.scss';

.Stub {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_header {
    @include HeaderHeight;
    height: $HeightIncludeHeader;
  }

  &_menu {
    @include Left-menu-padding;
  }

  .logo {
    display: block;
    width: 120px;
    height: 120px;
    z-index: 100;
    animation: jump .8s ease-in infinite;
  }
  .logo path.bg-white{
    fill: #dddddd;
  }
  .logo path.bg-orange {
    fill: #F99E46;
  }
  .logo path.pryanik-1,
  .logo path.pryanik-2,
  .logo path.pryanik-3 {
    fill: #ffffff;
  }
  .shadow {
    z-index: -1;
    border-radius: 100%;
    height: 10px;
    width: 120px;
    background: #000;
    box-shadow: 0px 0px 20px 10px black;
    opacity: 0.5;
    animation: scale-shadow .8s ease-in infinite;
  }
}

@keyframes jump {
  0% {
      transform: translateY(0) scale(1.15,.75);
  }
  20% {
      transform: translateY(-35px) scaleY(1.1);
  }
  50% {
      transform: translateY(-50px) scale(1);
  }   	
  80% {
      transform: translateY(-35px) scale(1);
  }
  100% {
      transform: translateY(0) scale(1.15,.75);
  }
  }
  @keyframes scale-shadow {
  0% {
      opacity: .5;
      transform: scale(1);
  }
  50% {
      opacity: .1;
      transform: scale(.4);
  }
  100% {
      opacity: .5;
      transform: scale(1);
  }
  }
  