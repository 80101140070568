.SkeletonProduct {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;

  &-Image {
    padding-top: 66%;
    background-color: #E3E3E3;
  }
  &-Info {
    padding: 12px 24px;
  }
  &-Footer {
    padding-top: 2.5rem;
    border-top: 1px solid var(--border-color);
  }
}