.AttachedFileBox {
    margin: 6px 0;
    .AttachedFileItem {
        &.focused {
        }
        padding: 0;
    }

    &-Loader {
        border: 1px solid #d3d9e0;
    }

    &-UploadBox {
        display: flex;
        padding: 7px 7px 3px 7px;
        justify-content: space-between;
    }

    &-Name {
    }

    &-TimesBtn {
    }

    &-Progress {
    }
}
