.Slider {
    height: 15px;
    border-radius: 7.5px;
    background-color: #d7d7d7;
    // overflow: hidden;
}

.Slider-Progress {
    width: 50%;
    background-color: var(--highlight-color);
    border-radius: inherit;
    height: 100%;
    position: relative;
    /*transition: all .05s ease-in-out;*/
}

.Slider-Dot {
    position: absolute;
    width: 15px;
    height: 15px;
    top: -2px;
    right: -7.5px;
    background-color: green;
    cursor: pointer;
    opacity: 0;
    /*transition: all .05s ease-in-out;*/
}

.Slider-Dot_active {
    background-color: blue;
}