.CollapseBox {
  
}

.CollapseBox-Toggle {
    display: flex;
    cursor: pointer;
    background: #f7f7f7;
    padding: 9px 24px;
    border-bottom: 1px solid #d3d9e0;
}

.CollapseBox-Toggle-Text {
    margin: 0;
    flex: 1 1;
}

.CollapseBox-Toggle-Icon {}