.GifViewer {
    &-Gif {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &-PlayBtn {
        // display: none;
        position: absolute;
        width: 84px;
        height: 84px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-shadow: 0px 0px 10px #000;
    }

    &-PlayIcon {
        font-size: 60px;
        color: #fff;
    }
}
