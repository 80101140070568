@import 'src/utils-old/global.scss';

.VerticalScroll {
  @include HiddenScrollbars;
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
  // height: 100%;
  &-Track {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 8px;
  }
  &-Thumb {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    .VerticalScroll-Track:hover &,
    &_visible {
      opacity: 1;
    }
  }
}
