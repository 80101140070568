.AudioPlayer-Analyzer {
  display: flex;
  width: 25px;
  height: 16px;
  overflow: hidden;
  text-align: center;
  justify-content: space-between;
  align-items: flex-end;

  .line-1,
  .line-2,
  .line-3 {
      position: relative;
      width: 30%;
      height: 1%;
      background-color: #ccc;
      animation-name: height;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
  }

  .line-1 {
      animation-delay: .3s;
      animation-duration: 550ms;
  }

  .line-2 {
      animation-delay: .1s;
      animation-duration: 300ms;
  }

  .line-3 {
      animation-delay: .4s;
      animation-duration: 400ms;
  }

  @keyframes height {
      from {
          height: 1%;
      }

      50% {
          height: 90%;
      }

      to {
          height: 1%;
      }
  }
}