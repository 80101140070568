.DropdownSelect {
    display: inline-flex;
}
.DropdownSelect-Container {
    background: #fff;
    position: absolute;
    z-index: 5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    top: 45px;
}
