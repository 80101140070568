.CommonmarkRender {
    &-Image {
        &_isFloat {}
    }
}

.CommonmarkRender-Image+.CommonmarkRender-Softbreak {
    display: none;
}

.CommonmarkRenderImgBlock {
    &_isFloat {}

    &-Img {}

    &-Expand {
        display: none;
    }

    &:hover {
        .CommonmarkRenderImgBlock-Expand {
            display: block;
        }
    }
}