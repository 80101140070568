@import "src/utils-old/global.scss";

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .Dropdown {
        &_align_center {
            display: inline-block;
        }

        &-Button {
            display: inline;
        }
    }
}
