.Toggle {
  width: 56px;
  height: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #B3B3B3;
  outline: none;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0,0,0, .2);
  transition: 0.5s;
  position: relative;
}
.Toggle:checked {
  background: #9CBACA;
}
.Toggle::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1.1);
  box-shadow: 0 3px 5px rgba(0,0,0, .4);
  transition: 0.3s;
}
.Toggle:checked::before {
  background: #3A87AD;
  left: 32px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  .Toggle {
    width: 13px;
    height: 13px; 
    cursor: pointer;
  } 
}