.FieldPhoto {
  margin-bottom: 6px;
  font-size: 16px;
  &-Loader {
    padding: 0 !important;
    width: fit-content;
  }

  &-Label {
    margin-bottom: 6px;
    .MuiFormControl-root {
      margin-bottom: 0 !important;
    }
  }
}