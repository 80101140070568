.PryanikyVideo {
  display: block;
  text-align: center;
  cursor: default !important;
  position: relative;
  // min-height: 200px;
  overflow: hidden !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #000000;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  &-Spinner {
    visibility: visible;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;

    i {
      font-size: 40px !important;
      margin-top: -20px;
      margin-left: -20px;
    }
  }

  &.Init &-Spinner {
    visibility: hidden;
    display: none;
  }

  &-Inner {
    // display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    height: 100%;
    width: 100%;
    // height: 70vh;
    // visibility: hidden;

    &-Preview {
      cursor: pointer;

      max-width: 100%;
      max-height: 100%;
    }

    &-Button {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: 50%;
      margin-left: -35px;
      cursor: pointer;
    }

    &-Logo {
      position: absolute;
      width: 100px;
      bottom: 6px;
      right: 6px;
    }

    &-Player {
      // position: absolute;
      // top: 0;
      // left: 0;
      // bottom: 0;
      // right: 0;

      height: 100%;
      width: 100%;
    }
  }

  &.Init &-Inner {
    visibility: visible;

    img {
      width: auto;
      height: auto;
      display: block;
    }
  }
}

.PostView-Body-Text {
  .PryanikyVideo {
    margin-top: 6px;

    & + p {
      margin-top: 6px !important;
    }
  }
}
