.QRDialog {

  &-Body {
    display: flex;
    justify-content: center;
  }

  &-Code {
    width: 100%;
    min-height: 300px;
  }
}