.PostItemSkeleton {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 24px 24px;
  // margin: 12px 0;
  margin-bottom: 24px;
  &-Header {
    display: flex;
    align-items: center;
  }
  &-Divider {
    border: none;
    border-top: 1px solid var(--border-color);
    margin: 0 -24px;
    padding-bottom: 24px;
  }
}