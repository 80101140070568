.InfinityListHeader {
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: transparent;    
    margin-top: 8px;

    &_disableBorder {
        border-radius: 0;
    }

    &-Main {
        display: flex;
        align-items: center;
        padding: 24px;
        &_disableMainPadding {
            padding: 0;
        }
        &_between {
            justify-content: space-between;
        }
    }

    &-GroupAction {
        display: flex;
        align-items: center;
        margin-right: 24px;
    }

    &-Checkbox {
        white-space: nowrap;
    }

    &-DropdownWithText {
    }

    &-SearchBox {
        width: 45%;
        margin-right: 24px;
    }

    &-Search {
        min-width: 170px;
    }

    &-PrimaryAction {
    }

    &-SecondaryAction {
        margin-left: auto;
        margin-right: 0px;
    }

    &-Children {
    }

    &-PrimaryBtn {
    }

    &-BottomSpace {
        border-top: 1px solid #d3d9e0;
    }
}
