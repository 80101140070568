@import 'src/utils-old/global.scss';

.Dropdown {
  position: relative;

  &_align_center {
    display: flex;
    justify-content: center;
  }

  &-Button {
    height: 100%;
    display: inline-flex;
    align-items: center;

    &_active {
      background-color: var(--main-color);
      color: var(--invariant-text-color);
    }
  }

  &-OptionContent {
    padding: 6px;
  }

  &-OptionIcon {
    margin-right: 12px;
  }

  &-Caret {
    margin-right: auto;
    font-size: 10px;
    width: 1rem;
  }

  &-Options {
    position: absolute;
    z-index: 1;
    top: 100%;
    min-width: 150px;
    // max-width: 250px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    overflow: hidden;
    border-radius: 4px;
    @include BoxShadow;

    &_align_right {
      right: 0;
      transform-origin: top right;
    }

    &_align_left {
      left: 0;
      transform-origin: top left;
    }

    &_align_center {
      transform-origin: top center;
    }

    &_entering {
      animation: slideInDown 0.3s ease-out;
    }

    &_exiting {
      animation: fadeOut 0.3s ease-out;
    }

  }

  &-Option {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    color: var(--text-color) !important;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: var(--main-color);
      color: var(--invariant-text-color) !important;
    }

    &_active {
      background-color: #7BBCF0;
    }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}