@import "src/utils-old/global.scss";

body.hideScroll {
  overflow: hidden;
  padding-right: 17px;
}

.Dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 150;

  &-AdditionalInformation {
    margin: 0 24px;
    color: rgb(128, 128, 128);
    padding-top: 8px;
  }

  &-Body {
    display: flex;
    flex-direction: column;
    position: relative;
    // margin: 20vh auto auto;
    max-width: 700px;
    max-height: 90vh;
    width: calc(100% - 24px);

    &_entering {
      animation: bounceInDown linear 0.35s;
    }

    &_exiting {
      animation: bounceOutUp linear 0.35s;
    }
  }

  &-BodyWrapper {
    background-color: var(--background-color);
    @include Border;
    @include BoxShadow;
    // @include BorderRadius;
    @include BorderRadiusTop;
    @include BorderRadiusBottom;
    @include HiddenScrollbars;
    overflow: visible;
  }

  &-Backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.3);
  }

  &-Header {
    @include BorderRadiusTop;
    padding: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-highlight-color);
  }

  &-Content {
    background-color: var(--background-highlight-color);
    padding: 16px;
  }

  &-HeaderContent {
    flex: 1;
  }

  &-Major {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-Close {
    font-size: 20px;
    line-height: 40px;
    margin-top: -8px;
    margin-right: -15px;
    margin-left: 25px;

    &:hover {
      text-shadow: #0000007a 0px 0px 6px;
    }

    .Icon_cross-mark:before {
      content: "\ee24";
      font-weight: 600;
      color: #ccc6;
    }
  }

  &-Actions {
    padding: 12px 24px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-highlight-color);
    text-align: center;
    @include BorderRadiusBottom;

    .Button+.Button {
      margin-left: 12px;
    }
  }

  &-Text {
    padding: 12px 24px;
    margin: 0;
  }
}

@keyframes bounceInDown {
  0% {
    transform: translateY(-300%);
  }

  70% {
    transform: translateY(20%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0%);
  }

  30% {
    transform: translateY(20%);
  }

  to {
    transform: translateY(-300%);
  }
}