.ImageLoaderCropper {

  &-Input {
    display: block;
    margin: 10px 0 0;
  }

  &-ReactCrop {
    text-align: center;
    margin: 10px 0 0;

    .ReactCrop {
      background-color: transparent;
    }

    .ReactCrop__image {
      max-height: 500px;
    }
  }

  &-Preview {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-Item {

      &:nth-child(n + 2) {
        margin-left: 20px;
      }

      &_base {
        max-width: 95%;
        max-height: 500px;
      }
    }
  }

  &-Cropper {
    text-align: center;
    margin: 10px 0;

    &-Input {
      display: block;
    }

    &-Preview {
      margin-top: 10px;

      > * {
        vertical-align: middle;
      }
      
      > *:nth-child(n + 2) {
        margin-left: 20px;
      }
    }
  }
}