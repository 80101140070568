.ProgressCircle {
  &-Svg {
    transform: rotate(-90deg);
  }
  &-Circle {
    stroke-width: 8px;
    fill: transparent;
    stroke: #d7d7d7;
  }
  position: relative;
}
