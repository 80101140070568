.Radio {
  display: flex;
  align-items: center;
  padding: 5px 15px;

  &-Icon {
    min-width: 12px;
    width: 12px;
    margin-right: 8px;
  }

  &-Input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    &:focus + .Radio-Icon {
      filter: drop-shadow(0 0 2px black);
    }
  }

  &_disabled {
    opacity: 0.5;
  }
  &-Text {
    flex: 1;
    line-height: 2;
  }
}
