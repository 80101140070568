.CustomScrollbars-Thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.CustomScrollbars-Track {
  height: 100%;
  z-index: 95;
  right: 0;
}

@media (min-width: 576px) {
  .CustomScrollbars-Thumb {
    border-radius: 5px !important;
  }
  .CustomScrollbars-Track {
    width: 10px !important;
    right: 2px;
  }
}
