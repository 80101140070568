.CharacteristicsValueTCommon {
    border: 1px solid #0066CC;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: inherit;

    &_isSelected {
        background: #0066CC;
        color: #fff;
    }

    &_isDisabled {
        background: #ccc;
    }
}