.ErrorsLog {
    &-Item {
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-Log {
        max-height: 200px;
        overflow-y: auto;
    }
}