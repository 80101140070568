.Pie {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &-Legend {
    // position: absolute;
    font-size: 14px;
    top: 6px;
    width: 100%;
    .vx-legend {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}