.PageHeader {
    padding: 24px;
    border: 1px solid #d3d9e0;
    background-color: #f7f7f7;
    border-radius: 8px;
    position: relative;

    &-Top {
        display: flex;
        align-items: center;
    }

    &-Avatar {
        margin-right: 12px;
    }

    &-PrimaryText {
        font-weight: 700;
        font-size: 16px;
    }

    &-Primary {
        display: flex;
        align-items: center;
    }

    &-PrimaryActions {
        margin-left: 8px;
    }

    &-Secondary {
        color: rgba(0, 0, 0, 0.54);
    }

    &-SecondaryActions {
        margin-right: 0;
        margin-left: auto;
        align-self: flex-start;
    }

    &-Tabs {
        display: flex;
        justify-content: center;
    }
}
