.FileViewer-View_type_ImagePreview {
    .pan-container {
        cursor: grabbing;
        div {
            transition: all 50ms linear;
        }
    }
}

.FileViewer-View-ZoomBox {
    position: fixed;
    top: 0;
    left: 30px;
    z-index: 5;
    bottom: 0;
    height: 150px;
    margin: auto;
    width: 45px;

    .FileViewer-View-ZoomPlusBtn,
    .FileViewer-View-ZoomMinusBtn,
    .FileViewer-View-FullSizeBtn,
    .FileViewer-View-CoverBtn {
        font-size: 30px;
        color: #fff;
        text-shadow: 0px 0px 10px #000;
    }
}
.FileViewer-View-ZoomBox.FileViewer-View-ZoomBox_mobile {
    position: absolute;
}