.iframeContainer {
    width: 98%;
    height: 98%;
    margin: auto;
    position: relative;
    padding-bottom: 56.25%;
}

.videoOverlay {
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 25;
    background-color: rgba(0, 0, 0, 0.7);
}
.videoContainer {
    // padding: 5px;
    &.focused {
        // box-shadow: inset 0 0 0 3px #accef7;

        .videoOverlay {
            display: block;
        }
    }
}

.iframe {
    width: 100%;
    height: 100%;
    position: absolute;
}
