.SearchInput {
  display: flex;
  align-items: center;
  height: 24px;
  max-width: 500px;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 0 10px;
  background-color: var(--background-color);

  &-Input {
    display: block;
    flex-basis: 100%;
    line-height: 24px;
    height: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
  }

  &-Button {
    color: var(--text-muted-color);
  }

  &-Spinner {
    position: relative;
    top: auto;
    left: auto;
    z-index: auto;
    height: 24px;
    width: 24px;  
    background-color: transparent;
    overflow: hidden;
    color: var(--text-muted-color);

    .Spinner-Icon {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
      margin-left: 0;
    }
  }
}
