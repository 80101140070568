.InfinityList {
    width: 100%;
    border: 1px solid #d3d9e0;
    background-color: #fff;
    border-radius: 8px;
    &_disableBorder {
        border:none;
        border-radius: 0;
    }

    &-More {
        width: 100%;
    }

    &-ErrorMsg,
    &-Trigger,
    &-Again {
        margin: auto;
    }
}
