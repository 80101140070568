@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */
    .ButtonBox {

        display: block;

        &_buttonRatio_equal {
            display: flex;
        }

        .Button {
            // min-width: 175px;
        }

        >* {
            display: inline-block;
            // flex: 1 1 100%;
            // width: 100%;
        }
    }
}