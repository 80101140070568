.BadgeSelector {
  &-Dialog {
    z-index: 2000;
  }
  &-Badge {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    color: inherit;
    cursor: pointer; 
    &:hover {
      background-color: var(--main-color);
      color: var(--invariant-text-color);
    }
    &Info {
      flex-grow: 1;
      margin-left: 24px;
    }
    &Name,
    &Description {
      margin: 0;
    }
  }
  &-Search {
    max-width: 500px;
    margin: 0 auto;
  }
  &-Placeholder {
    text-align: center;
    padding: 24px;
  }
}

.Badge-Text-Item {
  margin: 0;
}