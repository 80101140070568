.CommonmarkRender {
    &-ListItem {
        &>.CommonmarkRender-Paragraph {
            margin-bottom: 0px;
        }
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .CommonmarkRender {
        &-ListItem {
            .CommonmarkRender-Paragraph {
                display: inline;
            }
        }
    }
}