.InfinityListItemLayout {
    flex-wrap: wrap;

    &-Children {
        flex: 1;

        @media (max-width: 450px) {
            display: flex !important;
            justify-content: center !important;
            flex-direction: column !important;
            text-align: center !important;
        }
    }

    &-ListItemText {
        max-width: 350px;
    }

    &-ListItemAvatar {
        margin-right: 12px;
    }

    @media (max-width: 450px) {
        justify-content: center !important;
        flex-direction: column !important;
    }
}