.emojiSelect {
  display: inline-block;
}

.emojiSelectButton,
.emojiSelectButtonPressed {
  margin: 0;
  padding: 0;
  width: 2.5em;
  height: 1.5em;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: 1.5em;
  color: #888;
  background: transparent;
  border: none;
  border-radius: none;
  cursor: pointer;
}

.emojiSelectButton:focus,
.emojiSelectButtonPressed:focus {
  outline: 0;
  /* reset for :focus */
}

.emojiSelectButton:hover,
.emojiSelectButtonPressed:hover {
  background: transparent;
}

.emojiSelectButton:active,
.emojiSelectButtonPressed:active {
  background: transparent;
}

.emojiSelectButtonPressed {
  background: transparent;
}

.emojiSelectPopover {
  margin-top: 10px;
  padding: 0 .3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
}

.emojiSelectPopoverClosed {
  display: none;
}

.emojiSelectPopoverTitle {
  margin: 0 0 .3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.emojiSelectPopoverGroups {
  margin: 0 0 .3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}

.emojiSelectPopoverGroup {
  padding: 0 .5em;
}

.emojiSelectPopoverGroup:first-child .emojiSelectPopoverGroupTitle {
  display: none;
}

.emojiSelectPopoverGroupTitle {
  margin: 1em 0;
  padding-left: .5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.emojiSelectPopoverGroupList {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.emojiSelectPopoverGroupItem {
  width: 2.5em;
  height: 2.5em;
}

.emojiSelectPopoverGroupItem:empty {
  display: none;
}

.emojiSelectPopoverToneSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.emojiSelectPopoverToneSelectList {
  margin: .3em;
  padding: .3em;
  position: absolute;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.emojiSelectPopoverToneSelectItem {
  width: 2.5em;
  height: 2.5em;
}

.emojiSelectPopoverToneSelectItem:first-child {
  border-right: 1px solid #e0e0e0;
}

.emojiSelectPopoverEntry,
.emojiSelectPopoverEntryFocused {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.emojiSelectPopoverEntryFocused {
  background-color: #efefef;
}

.emojiSelectPopoverEntryIcon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.emojiSelectPopoverNav {
  margin: 0;
  padding: 0 .5em;
  display: flex;
  width: 20em;
  list-style: none;
}

.emojiSelectPopoverNavItem {
  width: 2.5em;
  height: 2.5em;
}

.emojiSelectPopoverNavEntry,
.emojiSelectPopoverNavEntryActive {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}

.emojiSelectPopoverNavEntryActive {
  color: #42a5f5;
}

.emojiSelectPopoverScrollbar {
  position: absolute;
  right: 0;
  top: .3em;
  bottom: .3em;
  width: .25em;
  background-color: #e0e0e0;
  border-radius: .125em;
  opacity: .1;
  transition: opacity .4s;
}

.emojiSelectPopoverScrollbarThumb {
  background-color: #000;
  border-radius: .125em;
  cursor: pointer;
}

.emojiSelectPopoverGroups:hover .emojiSelectPopoverScrollbar {
  opacity: .3;
}

.emojiSelectPopoverGroups .emojiSelectPopoverScrollbar:hover,
.emojiSelectPopoverGroups .emojiSelectPopoverScrollbar:active {
  opacity: .6;
}