.Attachments-Item.Attachments-Item_state_view {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-bottom-width: 0px;

    &:last-of-type {
        border-bottom-width: 1px;
    }

    &:hover {
        .Attachments-Item-BtnGroup {
            display: block;
        }

        .Attachments-Item-BtnGroup_isLoading {
            display: none;
        }
    }
}