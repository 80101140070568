.TablePlugin-SizeSelector {
    background-color: #fff;
    width: 130px;
    .TablePlugin-SizeSelector-Cell {
        width: 20px;
        height: 20px;
        background-color: #aaa;
    }

    .active {
        background-color: #aaf;
    }
}