.FieldCategory {
    &-Title {
        &:empty {
            display: none;
        }

        &_isExpansion {
            margin: 0;
        }
    }

    &-Fields {
        &_emptyCategory {
            margin-top: 24px;
        }

        &_isExpansion {
            flex: 1;
        }
    }
}