.linkTool {
  /* left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
  z-index: 2;
  box-sizing: border-box; */
  display: inline-flex;
}
.linkTool-input {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 4px;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
  vertical-align: bottom;
}
/* .linkTool:after, .alignmentTool:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}


.linkTool:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
} */
