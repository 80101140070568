.PryanikyEditorV1 {
  position: relative;

  .SecretBaseContent {
    border: none;

    padding: 0;
  }


  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  .DraftEditor-editorContainer {
    min-height: auto;
    width: 100%;

    ul,
    ol {
      list-style-position: inside;

      li {
        .public-DraftStyleDefault-block {
          display: inline; //-block;
        }

        .Button {
          display: inline;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  &-Toolbar {
    z-index: 50;
    position: sticky;
    top: 60px;
    flex-wrap: wrap;

    .Spacer {
      flex: 1;
    }

    .Delimiter {
      border-left: 1px solid #d3d9e0;
    }

    &.small {
      .Delimiter {
        display: none;
      }
    }
  }

  .SubToolbar {
    display: flex;
    background-color: #fff;
    border: 1px solid #d3d9e0;
    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
    flex-wrap: wrap;
  }

  figure {
    position: relative;
    margin: 0;
  }

  .DraftEditor-ImgOverlay {
    height: 100%;
    background-color: #00ffff;
    // padding: 0px;
    box-shadow: inset 0px 0px 3px 3px #00f;
    // border: 2px solid #1304e6;
    opacity: .5;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: black;
    cursor: ew-resize;
    // width: 92%;
    // height: 100%;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  .alignment--center {
    text-align: center;
  }

  .alignment--left {
    text-align: left;
  }

  .alignment--right {
    text-align: right;
  }

  .alignment--justify {
    text-align: justify;
  }
}