// .TabledEditor {
//     position: relative;
//     z-index: 1;
// }

.PrnEditorTable {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0px;

    // th,
    // td {
    //     padding: 6px;
    // }
    .NestedEditor {
        overflow: hidden;
        width: 100%;
    }

    td {
        vertical-align: top;
        overflow: hidden;
    }

    .active_cell {
        // resize: horizontal;
        overflow: auto;
        // outline: inset #00f;
        box-shadow: inset 0px 0px 3px 1px #00f;
    }

    .active_col {
        // resize: both;
        overflow: auto;
    }

    &_edit {

        th,
        td {
            border: 1px dotted;
            border-color: #000;
            overflow: unset;
        }

        .active_cell {
            overflow: unset;
        }

        .NestedEditor {
            overflow: unset;
        }

        .active_col {
            overflow: unset;
        }
    }
}

.PrnEditor {
    &-Table {
        border-collapse: collapse;
    }

    &-Table_zebra {
        border-collapse: collapse;

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
    }

    &-Table_withBorder {
        border-collapse: collapse;
        border: 1px solid black;

        th,
        td {
            border: 1px solid;
            border-color: inherit;
        }
    }

    &-Table_underline {
        border-collapse: collapse;

        th,
        td {
            border-bottom: 1px solid;
            border-color: inherit;
        }
    }

    &-NestedImg {
        width: 100%;
        display: block;
        // margin: auto;
        height: auto;
    }
}