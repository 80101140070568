.TOBarChart {
    &-Rect {
        box-shadow: 1px 1px 1px 1px #000;
    }

    &-BarText {
    }

    &-Svg {
        display: block;
    }

    &-UserList {
        line-height: 0;
        text-align: right
    }
    &-UserListBox {
    }
}
