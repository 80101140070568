.StickyTable {
  position: relative;
  border-collapse: collapse;
  min-width: 100%;
  // width: 150%;
}

.StickyTable-Wrapper {
}

.StickyTable-td,
.StickyTable-th {
  padding: 9px 0.25em;
  text-align: left;
}

.StickyTable-tr {
  // box-shadow: 0px 1px 0px 0px #d3d9e0;
  border-bottom: 1px solid #d3d9e0;
}

.StickyTable-th {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  // box-shadow: 1px 1px 0px 0px #d3d9e0;
  &_left {
    text-align: left;
    width: 20rem;
    // box-shadow: 1px 1px 0px 0px #d3d9e0;
  }
}

.StickyTable-thead .StickyTable-th {
  top: 0;
  z-index: 1;
}

.StickyTable-thead .StickyTable-th:first-child {
  left: 0;
  z-index: 2;
}

.StickyTable-tbody .StickyTable-th {
  z-index: 1;
  left: 0;
}
