.HorizontalSeporatorPlugin {
    height: 10px;

    &-Line {
        position: relative;
        display: flex;

        &_focused {
            box-shadow: 0 0 0 3px #ACCEF7;
        }
    }

}