.EmbeddedHtmlPlugin {
    position: relative;
    &-Overlay {
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 25;
        background-color: rgba(0, 0, 0, 0.7);
    }
    &-Content {
        overflow: hidden;
    }
    &.focused {
        .EmbeddedHtmlPlugin-Overlay {
            display: block;
        }
    }
}
