@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .Preview {
        .Preview-Img {
            max-width: 100%;
            width: auto;
            height: auto;
            display: block;
            margin: auto;
            // object-fit: cover;
            max-height: 400px;
            cursor: pointer;
        }
        .Preview-ImgBox {
            border: 1px solid transparent;
        }
    }
}
