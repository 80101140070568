.CellPaddingSelector {
    &-Tab {
        padding: 12px;
    }

    &-InputBox {
        margin-bottom: 6px;
    }

    &-Accept {
        display: block;
        margin: auto;
    }

    &-Tabs {
        justify-content: center;
    }

    &-Title {
        font-size: 20px;
        text-align: center;
        margin-top: 6px;
    }

    &-Input {
        box-sizing: border-box;
        border: 1px solid #ddd;
        cursor: text;
        padding: 4px;
        margin-left: 12px;
        box-shadow: inset 0px 1px 8px -3px #ababab;
        background: #fefefe;
    }
}