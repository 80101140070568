.RanderSmall {
  padding: 24px;
  border-radius: 8px;

  &-Title {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &-Item {
    padding: 8px 24px;
    line-height: normal;
    display: flex;
  }

  &-GroupName {
    margin: 6px 0;
    font-weight: 600;
  }

  &-Name {
    color: var(--text-color);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    text-overflow: ellipsis;
    flex: 3;
    min-width: 120px;
    font-weight: normal;
    // font-size: 12px;
    font-size: 14px;
    line-height: 143%;
    width: 232px;

  }

  &-Icon {
    // font-size: 50px;
    // line-height: 72px;
    // text-align: center;
    margin-right: 16px;
    height: 40px;
  }



  &-RatingBox {
    flex: 1;
    overflow: hidden;
  }

  &-ProgressBar {
    width: 100%;
  }

  &-Value {
    font-weight: bold;
    margin: 0;
    text-align: left;
    font-size: 14px;
    line-height: 143%;
    // flex: 1;
    // min-width: 120px;
  }

  &-Description {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }

  .small {
    .RatingsWidget-Icon {
      margin-right: 6px;

      &:empty {
        display: none;
      }
    }

    .RatingsWidget-RatingBox {
      display: flex;
      margin-bottom: 6px;
    }

    .RatingsWidget-Name {
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
    }

    .RatingsWidget-Description {
      display: block;

    }

    .RatingsWidget-Value {
      text-align: left;
      font-size: 14px;
      //   line-height: 15px;
    }

    .RatingsWidget-Item {
      display: block;
    }

    .RatingsWidget-GroupName {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      margin: 0;
      margin-bottom: 8px;
    }

  }
}