.Skeleton {
  background-color: var(--placeholder-color);
  background-size: 100% 100%;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 8px 0;
  &_spacing_lg {
    margin: 12px 0;
  }
  &_spacing_xl {
    margin: 16px 0;
  }
  &_spacing_none {
    margin: 0;
  }
  &_spacing_sm {
    margin: 4px 0;
  }

  &_shape_line {
    background-image: #e6e5e5;//linear-gradient(90deg, #e6e5e5, #f5f5f5, #e6e5e5);
    // animation: skeleton-glow 1.2s ease-in-out infinite; //TODO: жрёт ресурсов как конь, нужно как то оптимизировать. Возможно через 3D трансформации
    height: 18px;
    &.Skeleton_size_xs {
      height: 8px;
      border-radius: 4px;
    }
    &.Skeleton_size_sm {
      height: 12px;
      border-radius: 6px;
    }
    &.Skeleton_size_lg {
      height: 24px;
      border-radius: 12px;
    }
    &.Skeleton_size_xl {
      height: 36px;
      border-radius: 18px;
    }
  }

  &_shape_circle {
    border-radius: 50%;
  }
  &_shape_circle,
  &_shape_square {
    height: 40px;
    width: 40px;
    overflow: hidden;
    &.Skeleton_size_xs {
      height: 20px;
      width: 20px;
    }
    &.Skeleton_size_sm {
      width: 32px;
      height: 32px;
    }
    &.Skeleton_size_lg {
      height: 56px;
      width: 56px;
    }
    &.Skeleton_size_xl {
      height: 88px;
      width: 88px;
    }
    &.Skeleton_size_xxl {
      height: 128px;
      width: 128px;
    }
    &.Skeleton_size_xxxl {
      height: 184px;
      width: 184px;
    }
  }
}

@keyframes skeleton-glow {
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: calc(100px + 100%) 0;
  }
}

@media (min-width: 576px) {
  .Skeleton {
    &_shape_line.Skeleton_size_lg {
      height: 16px;
    }
    &_shape_circle,
    &_shape_square {
      height: 56px;
      width: 56px;
    }
  }
}
