.MUIViewMatrix {
  width: 100%;
  text-align: center;

  &-NameCell {
    padding: 0 12px;
  }

  
  &-HelpTooltip{
      top: -174px;
      right: -5px;
      position: relative;
  }
  &-ErrorIcon:before {
    // right: 15px;
    // position: absolute;
    color: red;
  }

  &-Radio {
    width: 12px;
    height: 12px;
  }

}