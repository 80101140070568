.BadgeIconNew {
    // width: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 12px 0;
    position: relative;

    &_click {
        cursor: pointer;
    }

    &-ImgBox {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-Img {
        // border: 1px solid #fff;
        display: block;

        // height: 92px;

        .Avatar-Img {
            background-color: transparent;
        }
    }

    &-Name {
        text-align: center;
    }


    .Bable {
        border: 1px solid #0066CC;
        background:  #F5F5F5;
        box-shadow: none;
        border-radius: 20px;
        color:#0066CC;
    }

    &-Count {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 13px;
        line-height: 22px;
      

    }

    &-Remove {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        width: 100%;
        height: 100%;

    }

    .BadgeIconNew-RemoveBadge {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 2px;
        border: 1px solid #fff;
        opacity: 0;

    }

    &:hover {
        .BadgeIconNew-RemoveBadge  {
            position: absolute;
            right: 0px;
            top: 0;
            padding: 0 2px;
            // border: 1px solid #fff;
            opacity: 1;
            background-color: #fff;
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
            border-radius: 50%;
            box-sizing: border-box;
        }
        .BadgeIconNew-RemoveBadge_size_64{
            width: 25px;
            height: 25px;
        }
        .BadgeIconNew-RemoveBadge_size_140{
            width: 32px;
            height: 32px;
        }
        .BadgeIconNew-RemoveBadge_size_40{
            width: 20px;
            height: 20px;
        }
    }
}