.CellSeparation {
    padding: 8px;

    &-ButtonBox {
        margin-top: 8px;
    }

    &-Input {
        box-sizing: border-box;
        border: 1px solid #ddd;
        cursor: text;
        padding: 4px;
        width: 100%;
        box-shadow: inset 0px 1px 8px -3px #ababab;
        background: #fefefe;
    }
}