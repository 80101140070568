@import 'src/utils-old/global.scss';

.Textarea {
  // padding: 0 24px;
  position: relative;
  box-sizing: border-box;
  display: block;
  margin: 1px 0;

  &:focus-within {
    outline: 1px solid $SystemBlue;
  }

  &:hover {
    cursor: text;
  }
  &-HelpTooltip{
    right: 42px;
    top: 45px;
    position: absolute;
  }
  &-ErrorIcon:before {
    color: $ErrorColor;
  }

  &-ErrorIcon{
    position: absolute;
    font-size: 16px;
  }

  &-Icon {
    position: absolute;
    font-size: 16px;
    width: 16px;
    text-align: center;
    top: 8px;
    left: 24px;
  }

  &-Textarea {
    margin: 0;
    border: 0;
    padding: 10px;
    @include FontSize;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    font-family: $FontFamilyRegular;
    min-height: 120px;
    text-indent: 54px;
    display: block;
  }

  &_noIcon {
    .Textarea-Textarea {
      text-indent: 0;
    }
  }
}