.SecretSelector {
    &-Body {
        width: 768px;
    }

    &-SearchInput {
        font-size: 14px;
    }

    &-Trigger {
        
    }
}
