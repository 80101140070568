.VerticalStack {
  position: relative;
  width: 100%;
  &-Legend {
    // position: absolute;
    font-size: 14px;
    top: 6px;
    width: 100%;
    .vx-legend {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &-Tooltip {
    padding: 12px;
    font-size: 16px;
    width: 150px;
    & > div {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: bold;
      }
    }
  }

  &-NoSelect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}