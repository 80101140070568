@import "src/utils-old/global.scss";

.Attachments-Item.Attachments-Item_state_sound {
    display: flex;
    align-items: center;
    padding: 0;
    /*margin-bottom: 0;
    border-bottom-width: 0px;*/

    /*&:last-of-type {
        border-bottom-width: 1px;
    }*/

    .Attachments-Item-BtnGroup {
        position: absolute;
        right: 50px;
        top: 10px;
        height: 100%;
    }

    &:hover {
        .Attachments-Item-BtnGroup {
            display: block;
        }
    }
}

.Attachments-Item-TypeIcon {
    width: 20px;
    height: auto;
    display: block;
    padding: 0 10px;
}

.Attachments-Item-Name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
}

.Attachments-Item-BtnGroup {
    display: none;
}

.Attachments-Item-DownloadBtn {
    padding: 0 10px;
}

.Attachments-Item-ViewBtn {
    padding: 0 10px;
}

.Attachments-Item-PlayBtn {
    border-right: 1px solid $BorderColor;
}

.Attachments-Item-ImgBox {
    // flex: 1 1;
}