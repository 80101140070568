.ImageLoader {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 24px;

  &-Block {
    flex: 1;
    cursor: pointer;
    &_preview {
      position: relative;
      flex: 0 0 104px;
    }

    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }

  &-Title {
    font-weight: bold;
  }

  &-Progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 52px solid #e7e7e7;
    transition: border-width .3s linear;

    &_circle {
      border-radius: 50%;
    }

    &-Icon {
      font-size: 45px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &_success {
        color: #3dd13d;
      }

      &_error {
        color: red;
      }
    }
  }

  &-Delete {
    position: absolute;
    top: 0;
    right: 0;
    background: #ebeff2;
    color: #000;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    border-radius: 20px;
    cursor: pointer;
  }

  // &-ProgressBar {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 0%;
  //   height: 0%;
  //   transform: translate(0%, 0%);
  //   border-radius: 50%;
  //   background-color: #e7e7e7;
  //   transition: width .3s linear, height .3s linear, transform .3s linear;
  // }
}