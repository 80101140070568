.InfinityListItem {
    &-Children {
        display: flex;
        align-items: center;
        @media (max-width: 450px) {
            display: flex !important;
            justify-content: center !important;
            flex-direction: column !important;
            text-align: center !important;
        }
    }
}