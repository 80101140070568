.PieGorizontal {
  position: relative;
  display: flex;
  // justify-content: center;
  // flex-direction: column;
  align-items: center;
  width: 100%;
  &-Legend {
    // position: absolute;
    font-size: 14px;
    top: 6px;
    width: 40%;
    margin-left: 40px;
    .vx-legend {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}