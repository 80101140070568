.PublicationTitle {
    display: flex;
    align-items: center;

    &-AvatarBox {
        margin-right: 24px;
        width: 56px;
        height: 56px;
    }
    &-Avatar {
    }
    &-Section {
        flex: 1;
    }
    &-Main {
        line-height: 25px;
        color: #999999;
    }
    &-Additional {
        font-size: 12px;
        line-height: 22px;
        color: #999999;
    }
}
