.ModalDialogTitle {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    font-style: medium;
    // background-color: #f7f7f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 16px 20px 16px 24px !important;
    &-Header {
        flex: 1;
    }
    &-CloseBtn {
    }
    .MuiIconButton-root {
        padding: 4px;
        
    }
    // .MuiTypography-h6 {
    //     font-weight: 600;
    //     font-size: 20px;
    // }
}

.MuiDialogTitle-root {
    background: #fff!important;
}
