@import 'src/utils-old/global.scss';

.Confirm {
  background-color: var(--background-color);
  @include Border;
  @include BoxShadow;
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  margin-top: 0;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  align-self: start;
  z-index: 9999;
  animation: fadeInTop ease-in 0.3s;
  padding: 12px 24px;
  &_exiting {
    animation: fadeOutTop ease-in 0.3s;
  }
  &-Backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: flex-start;
  }
  &-Title {
    margin: 0;
  }
  &-Footer {
    padding-top: 12px;
    text-align: right;
  }
  &-Button {
    margin-right: 12px;
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-300%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutTop {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(-300%);
  }
}
