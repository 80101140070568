.AttachmentItem {
  position: relative;
  border: 1px solid #d3d9e0;
  padding: 5px 10px;

  &-Info {
    display: flex;
    justify-content: space-between;
  }

  &-Error {
    color: red;
  }

  &-Progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 0%;
    transition: width .2s ease-in-out;

    &_status_start,
    &_status_loading {
      background-color: gray;
    }

    &_status_loaded {
      background-color: green;
    }

    &_status_error {
      background-color: red;
    }
  }
}