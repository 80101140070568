.ProgressBar-Value {
  position: relative;
  border-radius: inherit;
  height: 100%;
  transform-origin: left center;
  transition: width 1s ease-in-out;
  background-color: var(--highlight-color);
  &_animated {
    animation: fillProgress 1s ease-in-out;
  }
  & > div {
    padding: 6px;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    position: absolute;
    background: white;
    right: -4px;
    bottom: -14px;
    border-radius: 50%;
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    display: flex;
  }
}

.ProgressBar-Bar {
  background-color: #d7d7d7;
  // overflow: hidden;
}

@keyframes fillProgress {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}