.TabsElement {
    display: flex;

    &-TabBtn {
        margin-left: 24px;
        border-bottom: 1px solid #979797;
        padding-bottom: 1px;

        &:first-of-type {
            margin-left: 0;
        }

        &_selected {
            padding-bottom: 0;
            border-bottom: 2px solid #0066CC;
        }

        &_align_right {
            margin-right: 24px;
            margin-left: auto;
        }
    }
}