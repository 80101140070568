.SecretEditor {
    .PryanikyEditorV1 {
        margin-top: 8px;
        .DraftEditor-editorContainer {
            max-width: 100%;
            width: auto;
            background-color: #d3d9e0;
            padding: 8px;
            border-radius: 8px;
        }
    }

    &-Content {
        padding: 8px;
        margin: 0 24px;
    }

    &-Fields {

    }

    &-Field {
        margin-top: 8px;
    }
    .ImageLoader {

        padding: 10px 10px;
    }
    .ImageLoader-Block_preview {

        flex: 0 0 88px;
    }
    .ImageLoaderPreview {
        width: 88px;
        height: 88px;
 
    }
}