.SearchSelectize {
  &-SearchBox {
    background-color: #f7f7f7;
    padding: 6px 12px;
  }

  &-Name {
    margin: 0px;
  }

  &-Name,
  &-Button {
    padding: 12px;
    padding-left: 24px;
  }
}
