.ProductCharacteristics {
    padding-top: 4px;

    &-Item {
        margin-top: 12px;

        &_inline {
            margin-top: 0px;
        }
    }

    &_isLoading {
        padding-top: 0px;
    }

    &-List {
        &_inline {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
        }
    }
}