

.Avatar {
  width: 20px;
  // height: 20px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  &_shape_rectangle {
    height: 150px;
  }
  &:before {
    content: "";
    float: left;
    padding-top: 100%;
    display: block;
  }

  &-Img,
  &-Letter {
    position: absolute;
    display: block;
    background-color: var(--background-highlight-color);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &_bordered {
      border: 1px solid var(--border-color);
    }
    &_noBorder {
      border: none;
    }
    &_noBorderRadius {
      border-radius: 0;
    }
    &_shape_circle {
      border-radius: 50%;
    }
    &_shape_rectangle {
      border-radius: 4px;
      height: 150px;
      width:100%;
    }
  }

  &-Letter {
    text-align: center;
    fill: #fff;
    // line-height: 20px;
    font-weight: bold;

    &-Text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    text{
      text-anchor: middle;
    }
  }

  &-Badge {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 85%;
    left: 85%;
    transform: translate(-50%, -50%);
    border: 2px solid white;
    box-sizing: border-box;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    text-align: center;
    line-height: 16px;
  }
}








// .Avatar {
//   width: 20px;
//   // height: 20px;
//   display: inline-block;
//   box-sizing: border-box;
//   position: relative;

//   &:before {
//     content: "";
//     float: left;
//     padding-top: 100%;
//     display: block;
//   }

//   &-Img,
//   &-Letter {
//     position: absolute;
//     display: block;
//     background-color: var(--background-highlight-color);
//     width: 100%;
//     height: 100%;
//     border-radius: 8px;
//     box-sizing: border-box;
//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;

//     &_bordered {
//       border: 1px solid var(--border-color);
//     }

//     &_noBorder {
//       border: none;
//     }

//     &_noBorderRadius {
//       border-radius: 0;
//     }

//     &_shape_circle {
//       border-radius: 50%;
//     }

//     &_shape_rectangle {
//       width: 100%;
//       height: 150px;
//       border-radius: 4px;
//       // border-radius: 50%;
//     }
//   }

//   &-Letter {
//     text-align: center;
//     fill: #fff;
//     // line-height: 20px;
//     font-weight: bold;

//     &-Text {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }

//     text {
//       text-anchor: middle;
//     }
//   }

//   &-Badge {
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     top: 85%;
//     left: 85%;
//     transform: translate(-50%, -50%);
//     border: 2px solid white;
//     box-sizing: border-box;
//     border-radius: 50%;
//     color: white;
//     font-size: 8px;
//     text-align: center;
//     line-height: 16px;
//   }
// }