.UserList {
    list-style: none;
    padding: 0;
    /*display: flex;
    flex-wrap: nowrap;
    overflow: hidden;*/
    margin: 0;

    &_underNext {
        padding-left: 22px;

        .UserList-Item {
            display: inline-block;
            vertical-align: text-top;
            padding: 0px;
            margin-left: -10px;
        }
    }
}

.UserList-Item {
    display: inline-block;
    vertical-align: text-top;
    padding: 2.5px;
}

// .UserList-Circle {
:root {
    --Circle-diameter: 19px;
    --Circle-border-color: #0066cc;
    --Circle-text-color: #0066cc;
    --Circle-bg-color: #fff;
    --Circle-size: calc(var(--Circle-diameter) + calc(var(--Circle-diameter) / 1.8));
}

.UserList-Circle {
    border-radius: 50%;
    // width: 32px;//calc(var(--Circle-size) * 1.2857);
    // height: 32px;//var(--Circle-size);
    // padding: calc(var(--Circle-diameter) / 4.5) 0;
    background: var(--Circle-bg-color);
    border: 1px solid var(--Circle-border-color);
    box-sizing: border-box;
    color: var(--Circle-text-color);
    text-align: center;
    // font-size: 14px;//calc(var(--Circle-diameter) / 2.2);
    font-family: "Open Sans bold";
    // line-height: 32px;//calc(var(--Circle-diameter) / 0.58);
    display: block;
}

.UserList-Item__end {
    margin-left: 0;
    margin-right: 0;
    display: inline-block;
    /* float: right; */
    padding: 2.5px 0;
}

.UserList-Button {
    cursor: pointer;
    background: transparent;
    position: relative;
    display: block !important;
    &:hover {
        text-decoration: none;
    }
    line-height: 0;
}
