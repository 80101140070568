.Tooltip {
    position: absolute;
    z-index: 1100;
    border-radius: 8px;
    background-color: var(--background-color);
    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.2));
    border: 1px solid var(--border-color);
    padding: 12px 12px;
    $arrow-size: 5px;
    animation: show 400ms ease-in-out;

    &_isDialog {
        //а с эти придется потерпеть
        z-index: 1500;
        position: fixed !important;
    }

    &.Tooltip_NoPadding {
        padding: 0;
    }

    &-Arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        left: 50%;

        &.top-arrow {
            top: -$arrow-size;
            border-width: 0 $arrow-size $arrow-size $arrow-size;
            border-color: transparent transparent #fff transparent;
        }

        &.bottom-arrow {
            bottom: -$arrow-size;
            border-width: $arrow-size $arrow-size 0 $arrow-size;
            border-color: #fff transparent transparent transparent;
        }
    }

    @keyframes show {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}