.AttachedImageItem {
    cursor: default;
    display: block;
    max-width: 100%;
    height: auto;
}

.AnimatedImage {
    width: fit-content;
    .GifViewer {
        &-Gif {
            width: 100%;
        }
    }
}

.AttachedImageBox {
    position: relative;
    z-index: -1;

    // margin: 6px 0;
    .AttachedImageItem {
        &.focused {}

        padding: 0;
    }


    .hack {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}