.DraftEditorAnchor {
    position: relative;

    &-Help {}

    &-Icon {
        position: absolute;
        right: -8px;
        top: -8px;
        font-size: 8px;
    }
}