.ProductCharacteristicsItem {
    display: flex;

    &_showSelectedTitle {
        display: block;
    }

    &-List {
        display: flex;
        flex-wrap: wrap;
        &_showSelectedTitle {
            margin-left: -8px;
        }
    }

    &-Value {
        margin-left: 8px;
        margin-bottom: 8px;
    }

    &-Name {
        font-weight: bold;
        &_showSelectedTitle {
            margin-bottom: 8px;
        }
    }
}