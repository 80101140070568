.SkeletonProductCard {
  display: flex;
  margin-top: 6px;
  &-Photos {
    width: 40%;
    margin-right: 57px;
     &-SelectedPhoto {
      width: 450px;
      height: 450px;
      width: 100%;
      background-color: #E3E3E3;
      border-radius: 8px;
      height: 440px;
    }

    &-Items {
      display: flex;
      flex-wrap: wrap;
      div {
        border-radius: 8px;
        width: 94px;
        height: 94px;
        margin-right: 24px;
        margin-top: 24px;
        background-color: #E3E3E3;
        &:nth-child(4n + 4) {
          margin-right: 0;
        }
      }
    }
  }

 

  &-Info {
    width: 50%;

    & > div {
    padding: 24px 0;
    border-bottom: 1px solid #E3E3E3;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    &:nth-child(3) {
      display: flex;
      align-items: center;
      & > div + div {
        margin-left: 12px;
      }
    }
  }
  }

  

}