.SecretContent {
    border: 1px solid #d3d9e0;
    border-radius: 8px;
    padding: 8px;

    &-MainBox {
        display: flex;
    }

    &-IconBox {
        margin-top: 4px;
        font-size: 35px;
        color: #d3d9e0;
        border: 2px solid #d3d9e0;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-DescriptionBox {
        margin-left: 24px;
        flex: 1;
    }

    &-Progress {
        margin-left: -8px;
        margin-right: -8px;
        margin-top: 8px;
    }

    &-Description {
        border-bottom: 1px solid #d3d9e0;
    }

    &-Buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }

    &-ShowBtn {}

    &-EditBtn {}

    &-HideBtn {}

    &-ContentBox {
        margin-top: 8px;
        padding: 8px;
        border-radius: 8px;
        background-color: #d3d9e0;
    }
}