.FileViewer-View {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: nowrap;
    justify-content: center;
}

.FileViewer-View-Img {
    display: block;
    width: auto;
    height: auto;
    margin: auto;
    //padding: 5%;
    //transition: all .3s linear;
    z-index: 1;
    //left: 0;
    //right: 0;
    //top: 0;
    //bottom: 0;
}

.FileViewer-Img_noSize {
    width: 0;
    height: 0;
}