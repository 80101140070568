.buttonWrapper {
  display: inline-flex;
}

.button {
  /* background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px; */
}

.button svg {
  /* fill: #888; */
}

.button:hover, .button:focus {
  /* background: #f3f3f3;
  outline: 0;  */
  /* reset for :focus */
}

.active {
  /* background: #efefef;
  color: #444; */
}

.active svg {
  /* fill: #444; */
}
