.SecretBaseContent {
    border: 1px solid #d3d9e0;
    border-radius: 8px;
    padding: 24px;

    &-Title {
        // border-bottom: 1px solid #d3d9e0;
        font-weight: 700;
        font-size: 16px;
        line-height: 116%;
        letter-spacing: 0.15px;
        padding: 6.5px 0;
        margin-bottom: 8px;

    }

    &-MainBox {
        // display: flex;
    }

    &-IconBox {
        // margin-top: 4px;
        // font-size: 35px;
        // color: #d3d9e0;
        // border: 2px solid #d3d9e0;
        // border-radius: 50%;

        // width: 240px;
        // height: 150px;
        
        // width: 55px;
        // height: 55px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
    }

    &-ViewedCount {
        font-size: 12px;
        font-weight: bold;
    }

    &-DescriptionBox {
        // margin-left: 24px;
        flex: 1;
    }

    &-Progress {
        margin-left: -8px;
        margin-right: -8px;
        margin-top: 8px;
    }



    &-Buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .MuiButtonBase-root {
            // margin-top: 20px;
            text-transform: uppercase;
        }
        @media (max-width: 450px) {
            justify-content: center;
        }
    }

    &-ShowBtn {}

    &-EditBtn {}

    &-HideBtn {}

    &-ContentBox {
        margin-top: 8px;
        padding: 8px;
        border-radius: 8px;
        background-color: #d3d9e0;
    }
}