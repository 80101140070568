.DropdownWithButton {
  &-Popper {
    z-index: 1;
  }

.MuiButton-label {
  text-transform: none;
}

  @media all and (-ms-high-contrast: none) {
    div .MuiGrid-container {
      display: block;
    }
  }
}