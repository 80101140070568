.Fonts-List {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 9px;
  margin-top: 9px;
  border-top: 1px solid #d3d9e0;
}

.Fonts-ListItem {
  border-bottom: 1px solid #d3d9e0;
  &:hover {
    background: #ccc;
  }

  &_active {
    background: #ccc;
  }
}

.Fonts-Btn {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  outline: none;
  padding: 0 19px;
  &.font-arial {
    font-family: 'Arial';
  }

  &.font-helvetica {
    font-family: Helvetica;
  }

  &.font-georgia {
    font-family: Georgia;
  }

  &.font-monospace {
    font-family: monospace;
  }

  &.header-five {
    font-size: 14px;
    line-height: 24px;
  }

  &.header-six {
    font-size: 10px;
    line-height: 20px;
  }
}
