.CharacteristicsValueTColor {
    width: 28px;
    height: 28px;
    border-radius: 7px;
    border: 2px solid transparent;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s ease-in-out;
    // box-shadow: 0px 0px 0px 2px #ccc;
    padding: 2px;
    box-sizing: border-box;
    &:hover {
        box-shadow: 0px 0px 2px 2px #ccc;
    }

    &-Center {
        border-radius: 4px;
        width: 20px;
        height: 20px;
    }

    &_isSelected {
        box-shadow: 0px 0px 2px 2px #ccc;
    }

    &_isDisabled {
        box-shadow: 0px 0px 0px 2px #ccc;
        background: #ccc;
    }
}