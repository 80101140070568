.SecretEdit {
    .ImageLoader {

        padding: 10px 10px;
    }
    .ImageLoader-Block_preview {

        flex: 0 0 88px;
    }
    .ImageLoaderPreview {
        width: 88px;
        height: 88px;
 
    }
}