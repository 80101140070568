.SortableItem {

  &_horizontal {
    display: flex;
    margin: 4px;
  }

  &-Body {

    &_isDragging {
      opacity: .5;
    }
  }

  &-Ghost {
    background-color: yellow;
  }

  &-Ghost {

    &.SortableItem {

      &-Ghost_overType_before {
        margin-right: 8px;
      }

      &-Ghost_overType_after {
        margin-left: 8px;
      }
    }
  }
}