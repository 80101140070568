.Attachments {
  display: flex;
  flex-direction: column;

  & > span {
    color: #94A4B5;
  }

  &-Images {
    display: flex;
    flex-wrap: wrap;
    // height: 100px;
    padding: 0 6px;
    &-Loader {
      height: 100px;
      width: 100px;
      // padding: 12px 0;
      cursor: pointer;
    }
    &-Item {
      position: relative;
      margin-right: 12px; 
      // height: 100px;
      &-Help {
        position: absolute;
        top: 0;
        left: 0;
      }
     
      &-Loading {
        filter: brightness(0.9);
      }
      
      &-Loaded {
       
          filter: brightness(0.9);
          cursor: pointer;
          position: relative;
          margin-right: 12px;
          .Attachments-Images-Item-Trash {
            font-size: 17px;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 5px;
            right: 3px;
            display: flex;
            color: #000;
            cursor: pointer;
          }

          .Attachments-Images-Item-Select {
            width: 20px;
            height: 20px;
            background-color: rgba(255,255,255, .5);
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #3f51b5;
            &-Active {
              background-color: #3f51b5;
              &:after {
                content: '';
                height: 10px;
                width: 5px;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(45deg);
                position: absolute;
                top: 3px;
              }
            }
          }
        
      }
      &-Progress {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      
      &-Image {
        height: 76px;
        background-color: #fff;
        padding: 12px;
       
      }
      
    }
  }

  &-Input {
    display: none;
  }
}


@media all and (-ms-high-contrast:none) {
  .Attachments-Images-Item-Loaded .Attachments-Images-Item-Select-Active::after {
    right: 5px;
  }

  .Attachments-Images-Item-Loaded {}
}
    