.Preview {
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap-reverse;
    flex-direction: row-reverse;

    .Preview-Img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-height: 400px;
        cursor: pointer;
    }
    .Preview-Video {
        width: 100%;
        height: 100%;
        display: block;
        max-height: 450px;
        margin: auto;
    }

    &-Gif {
        width: 100%;
        height: 100%;
        max-height: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &-GifBox {
        flex: 30% 1;
        border: 1px solid #fff;
        position: relative;
    }

    .Preview-ImgBox {
        flex: 30% 1;
        border: 1px solid #fff;
        position: relative;
    }

    .Preview-VideoBox {
        flex: 30% 1;
        border: 1px solid #fff;
        position: relative;
    }

    .Preview-AudioBox {
        flex: 30% 1;
        border: 1px solid #fff;
        position: relative;
    }

    .Preview-AudioPlayer {
        width: 100%;
    }

    .size_FULL {
        flex: 100% 1;
        .Preview-Img {
            max-width: 100%;
            width: auto;
            height: auto;
            display: block;
            margin: auto;
            object-fit: cover;
            max-height: none;
            cursor: pointer;
        }
    }

    .size_L {
        flex: 45% 1;
    }

    .size_XL {
        flex: 30% 1;
    }

    .size_XXL {
        flex: 20% 1;
    }

    .size_XXXL {
        flex: 14% 1;
    }

    .size_audio {
        flex: 100% 1;
    }

    .Preview-OpenBtn {
        display: none;
        position: absolute;
        width: 84px;
        height: 84px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-shadow: 0px 0px 10px #000;
    }

    .Preview-ImgBox:hover .Preview-OpenBtn {
        display: block;
    }

    .Preview-OpenIcon {
        font-size: 60px;
        color: #fff;
    }

    .Preview-ImgBox {
        // background-repeat: repeat;
        // background-size: 15px;
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ccc;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Echessboard_1%3C/title%3E%3Cg id='Слой_2' data-name='Слой 2'%3E%3Cg id='Слой_1-2' data-name='Слой 1'%3E%3Crect class='cls-1' width='100' height='100'/%3E%3Crect class='cls-1' x='100' y='100' width='100' height='100'/%3E%3Crect class='cls-2' y='100' width='100' height='100'/%3E%3Crect class='cls-2' x='100' width='100' height='100'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
}
