.SplitButton {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    border-radius: 8px;

    >* {
        font-size: 14px;
        padding: 0;
    }

    >.Button {
        &:first-of-type {
            flex: 1 1;
        }
    }

    .Button {
        padding: 10px;
    }

    &-Toggle {
        flex: 0;
    }

    &-List {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &-Item {
        border-bottom: 1px solid #d3d9e0;

        .Button {
            padding: 4px 8px;
            width: 100%;
            // &:hover {
            //     background-color: var(--main-color);
            //     color: var(--invariant-text-color);
            // }
        }

        &_active {
            background-color: var(--main-color);
            color: var(--invariant-text-color);
        }

        &:first-of-type {
            border-top: 1px solid #d3d9e0;
        }
    }
}