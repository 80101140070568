.UsersListDialog {
    &-ThanksUsersDialog {
        .InfinityList {
            &-More {
                visibility: hidden;
                // height: 0;
            }
        }

    }
}

.UsersListItemDialog {

    &-Contacts {
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-QRCode {
        opacity: 0;

        @media (max-width: 450px) {
            display: none !important;
        }
    }

    &:hover {
        .UsersListItemDialog-QRCode {
            opacity: 1;
        }
    }
}