.FieldViewLookup {
  &-Name {
    font-weight: bold;
  }
  &-Tags {
    a {
      border: 1px solid #d3d9e0;
      border-radius: 40px;
      padding: 3px 12px;
      margin-right: 12px;
    }
  }
}