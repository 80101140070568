.AttachedImageBox {
    position: relative;
    // margin: 6px 0;
    .AttachedImageItem {
        &.focused {
        }
        padding: 0;
    }
    .hack {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}
