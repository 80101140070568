.ModalDialogActions {
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; 
    padding: 8px 24px!important;
    &-CancelButton,
    &-AcceptButton {
        text-transform: uppercase!important;
    }
    
}
