@import 'src/utils-old/global.scss';

.Input {
  // position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 24px;
  margin: 1px 0;

  &:hover {
    cursor: text;
  }

  &:focus-within {
    outline: 1px solid $SystemBlue;
  }

  &-Icon,
  &-ErrorIcon {
    // position: absolute;
    font-size: 16px;
    // width: 16px;
    text-align: center;
    // top: 50%;
    // transform: translateY(-50%);
  }

  &-Icon {
    left: 15px;
    color: var(--text-muted-color);
  }

  &-HelpTooltip{
      top: 0px;
      right: 16px;
      position: relative;
  }
  &-ErrorIcon:before {
    // right: 15px;
    // position: absolute;
    color: $ErrorColor;
  }

  &-Input {
    margin: 0;
    border: 0;
    padding: 11px 10px 10px 18px;
    @include FontSize;
    width: 100%;
    box-sizing: border-box;
    font-family: $FontFamilyRegular;

    &_invalid {
      outline-color: $ErrorColor;
    }
    &_noIcon {
      padding-left: 10px;
    }
  }
}