.BarGroup {
  position: relative;
  width: 100%;
  &-Legend {
    font-size: 14px;
    top: 6px;
    width: 100%;
    .vx-legend {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &-NoSelect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}