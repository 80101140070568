.CommonmarkRender {
    &-Paragraph {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 21px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}