.CommonmarkRender {
    &-Code {
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        border-radius: 2px;
        color: #333;
        overflow: visible;
        display: inline;
        white-space: pre-wrap;
        margin-bottom: 5px;
        margin-top: 5px;
        padding: .05rem .2rem;
    }
}
