.FieldWikiBlock {

    .PryanikyEditorV1 {
        // border-bottom: 1px solid #D3D9E0;
        border: 1px solid #D3D9E0;
        border-radius: 4px;

        .prn-editor-container {
            padding-left: 0;
        }

        div.DraftEditor-editorContainer {
            padding: 0;
            padding-left: 32px;
            min-height: 50px;
        }

        .DraftEditor-root {
            padding: 18px 14px;
            &::before {
                content: "\ee27";
                font-family: 'pryaniky-icons';
                font-weight: normal;
                display: inline-block;
                text-decoration: inherit;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                margin-left: 0.2em;
                text-align: center;
                position: absolute;
                font-style: italic;
                color: #000;
                font-size: 17px;
                width: 17px;
                height: 17px;
                margin-right: 11px;
                line-height: 1.5;
            }
        }
        
        .public-DraftEditorPlaceholder-inner {
            color: rgba(0, 0, 0, 0.4);
            // line-height: 1;
            padding-left: 32px;
            &::before {
                display: none;
            }
        }

        .TabledEditor {
            .DraftEditor-editorContainer {
                min-height: 70px;
            }
        }
    }.PryanikyEditor {
        // border-bottom: 1px solid #D3D9E0;
        border: 1px solid #D3D9E0;
        border-radius: 4px;

        .prn-editor-container {
            padding-left: 0;
        }

        div.DraftEditor-editorContainer {
            padding: 0;
            padding-left: 32px;
            min-height: 50px;
        }

        .DraftEditor-root {
            padding: 18px 14px;
            &::before {
                content: "\ee27";
                font-family: 'pryaniky-icons';
                font-weight: normal;
                display: inline-block;
                text-decoration: inherit;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                margin-left: 0.2em;
                text-align: center;
                position: absolute;
                font-style: italic;
                color: #000;
                font-size: 17px;
                width: 17px;
                height: 17px;
                margin-right: 11px;
                line-height: 1.5;
            }
        }
        
        .public-DraftEditorPlaceholder-inner {
            color: rgba(0, 0, 0, 0.4);
            // line-height: 1;
            padding-left: 32px;
            &::before {
                display: none;
            }
        }

        .TabledEditor {
            .DraftEditor-editorContainer {
                min-height: 70px;
            }
        }
    }

}