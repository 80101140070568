.List-Placeholder {
  text-align: center;
  padding: 24px;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  // min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-Image {
    max-width: 250px;
    margin: 0 auto;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .List-Placeholder {
      .List-Placeholder-Image {
        display: block;
        margin: auto;
        width: 213px;
        height: 175px;
      }
    }
}
