.Actions {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 12px;

    @media (min-width: 576px) {
        padding: 14px 24px;
    }

    line-height: 24px;
    min-height: 24px;

    .Button {
        color: #999;
        margin-left: 6px; //4.71693%;
        display: flex;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .Icon {
        margin-right: 8px;
    }

    .Action {
        // margin-right: 40px;

        .Button {
            text-transform: capitalize;
        }
    }

    /*&:before {
    content: '';
    position: absolute;
    top: 0;
    border-top: 1px solid var(--border-color);
    display: block;
    width: calc(100% - 48px);
  }*/
}

.NewsView-Actions {
    .NewsView-Actions-BtnLikeNum {
        margin-left: 8px;
    }
}
