.TBLCell {
    // position: relative;
    padding: 0;
    min-height: 20px;

    .CellContainer {
        display: flex;
        align-items: stretch;
        // height: 100%;
        // align-items: center;
    }

    .CellResizeOverlay {
        padding: 0 3px;
        min-height: 15px;
        align-self: stretch;
        // height: 100%;
        user-select: none;
        // position: relative;
        z-index: 2;

        &:hover {
            background-color: #000000;
        }

        &_left {
            margin-right: -6px;
        }

        &_right {
            margin-left: -6px;
        }

        // height: 100%;
    }

    .EditColSpanOverlay {
        position: absolute;
        background-color: #00ffff;
        opacity: .3;

        &:hover {
            opacity: .7;
        }
    }

    .EditRowSpanOverlay {
        position: absolute;
        background-color: #ff00ff;
        opacity: .3;

        &:hover {
            opacity: .7;
        }
    }

    &.active_cell {
        .CellResizeOverlay {
            cursor: col-resize;

            &.mouseDown {
                height: 100%;
                background-color: #00ffff;
                // padding: 0px;
                box-shadow: inset 0px 0px 3px 3px #00f;
                // border: 2px solid #1304e6;
                opacity: .5;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                color: black;
                // width: 92%;
                // height: 100%;
                top: 0;
                bottom: 0;
                z-index: 10;
            }
        }
    }
}