.ErrorMsg {
    // margin: 24px;

    position: relative;
    padding: 0.75rem 1.25rem;
    // margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    display: flex;

    &-Message {
        flex: 1;
    }

    &_type_error {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    &_type_info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }

    &_type_warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }

    &_type_success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
}
