@import 'src/utils-old/global.scss';

.Button {
  // color: #000;
  padding: 0px;
  border-radius: 0px;
  background: transparent;
  border: 0px;
  outline: none;
  display: inline-block;
  cursor: pointer;
  font-family: $FontFamilyRegular;
  text-align: center;
  text-overflow: ellipsis;
  box-sizing: border-box;

  &_background {
    background-color: var(--background-color);
  }

  &_padding_sm {
    padding: 4px;
  }
  &_padding_md {
    padding: 6px 12px;
  }
  &_padding_lg {
    padding: 10px 20px;
  }

  &_border {
    border: 1px solid var(--border-color);
  }

  &_borderRadius {
    border-radius: 4px;
  }

  &_main,
  &_main.Button_type_link,
  &_main.Button_type_rlink {
    background-color: var(--main-color);
    color: var(--invariant-text-color);
    border-color: transparent;
    &:active {
      background-color: var(--text-muted-color);
    }
  }

  &_theme_unstyled {
    text-align: left;
  }

  &:disabled {
    text-decoration: none;
    cursor: default;
    background-color: var(--background-color);
    // border: 1px solid var(--border-color);
    color: var(--text-muted-color);
  }
}