.Dropzone {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  height: 0%;
  transition-property: height, border, background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  z-index: 1;

  &_init {
    height: 100%;
    border: 2px dashed;
    background-color: rgba(211, 217, 224, 0.65);
  }

  .Icon {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 30px;
    margin-top: -20px;
    margin-left: -20px;
  }
}
.NewsPostBlock {
  position: relative;
}