.CommonmarkRender {
    &-Mention {
        font-weight: bold;
        color: rgb(0, 102, 204);
        font-weight: bold;
        display: inline;
    }

    &-Video {}

    &-Link {
        // .Button {
        color: rgb(0, 102, 204);
        font-weight: bold;
        display: inline;
        text-overflow: ellipsis;
        overflow: hidden;
        // }
    }

    &-DefaultLink {
        color: rgb(0, 102, 204);
        font-weight: bold;
        display: inline;
    }
}