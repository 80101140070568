.FileViewer {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    left: 0;
    top: 0;
    // overflow: auto;
    display: flex;
    align-items: center;

    .FileViewer-CloseBtn {
        position: fixed;
        right: 30px;
        top: 30px;
    }

    .FileViewer-DownloadBtn {
        position: fixed;
        right: 30px;
        bottom: 30px;
    }

    .FileViewer-NextBtn,
    .FileViewer-PrevBtn,
    .FileViewer-Position,
    .FileViewer-DownloadBtn,
    .FileViewer-CloseBtn {
        z-index: 5;
        color: #fff !important;
        font-size: 30px;
        text-shadow: 0px 0px 10px #000;
    }


    .FileViewer-Switcher {
        position: fixed;
        bottom: 25px;
        left: 0;
        z-index: 5;
        margin: auto;
        right: 0;
        width: 250px;
        text-align: center;
    }

}

.FileViewer.FileViewer_mobile {
    position: relative;

    .FileViewer-Switcher.FileViewer-Switcher_mobile {
        position: absolute;
        bottom: 25px;
        left: 0;
        z-index: 5;
        margin: auto;
        right: 0;
        width: 250px;
        text-align: center;
    }
    .FileViewer-DownloadBtn.FileViewer-DownloadBtn_mobile {
        position: absolute;
        right: 30px;
        bottom: 30px;
    }
    .FileViewer-CloseBtn.FileViewer-CloseBtn_mobile {
        position: absolute;
        right: 30px;
        top: 30px;
    }
}