.AdminPageContent {
    &_styled {
        border: 1px solid #d3d9e0;
        background-color: #fff;
        border-radius: 8px;
        padding: 24px;
    }
    &_disablePadding {
        padding: 0;
    }
}

.AdminPageMain {
    display: flex;
    align-items: flex-start;
    &-Content {
        flex: 1;
    }
    &-Aside {
        width: 28%;
        margin-left: 24px;
        border: 1px solid #d3d9e0;
        background-color: #fff;
        border-radius: 8px;
    }
}
.AdminPageLayout {
    &-Header {
        margin-bottom: 24px;
    }
    &-SubHeader {
        margin-bottom: 24px;
    }
}
