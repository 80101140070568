@import 'src/utils-old/global.scss';

.Attachments-Item {
  position: relative;
  margin-bottom: 4px;
  padding-bottom: 3px;
  background-color: #fff;
  border: 1px solid $BorderColor;
  padding: 7px 7px;

  &-DeleteBtn {

  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &-Progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0%;
    background-color: #6e839c;
    transition: width $TransitionDuration $TransitionTimingFunction;

    &.success {
      background-color: #89ca5e;
    }

    &.finish {
      width: 100%;
    }

    &.error {
      background-color: #f00;
    }
  }

  &-Buttons {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -12px;

    &-Item {
      border-left: 1px solid $BorderColor;
      padding: 0 11px;
      vertical-align: middle;
    }
  }
}

.Attachments-Item-TypeIcon {
  width: 20px;
  height: auto;
  display: block;
  padding: 0 10px;
  &_pointer {
    cursor: pointer;
  }
}

.Attachments-Item-Name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;

  &_pointer {
    cursor: pointer;
  }
}

.Attachments-Item-BtnGroup {
  display: none;
}

.Attachments-Item-DownloadBtn {
  padding: 0 10px;
}

.Attachments-Item-ViewBtn {
  padding: 0 10px;
}

.Attachments-Item-ImgBox {
  // flex: 1 1;
}