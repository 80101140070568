@import 'src/utils-old/global.scss';

.CheckboxInput {
  position: relative;
  box-sizing: border-box;
  @include FontSize;
  padding: 8px 25px;
  width: 100%;
  display: flex;
  align-items: center;

  &_isInline {
    display: inline-flex;
  }

  &_padding_none {
    padding: 0;
  }

  &_padding_0 {
    padding: 0 24px;
  }

  &_padding_sm {
    padding: 4px 24px;
  }

  &_padding_lg {
    padding: 12px;
  }

  &:hover {
    cursor: pointer;
  }

  &-Icon {
    margin-right: 13px;
  }

  &-Text {
    // flex: 1 1;
  }

  &-Input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    &:focus + .Checkbox-Icon {
      box-shadow: 0 0 2px #6e839c;
    }
  }

  &_disabled {
    opacity: 0.3;
    cursor: default;
  }
}