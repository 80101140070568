.TabsV2 {
    display: flex;

    // border-bottom: 1px solid #d3d9e0;
    &-TabBtn {
        display: block;
        border-bottom: 1px solid #979797;
        padding-bottom: 1px;
        line-height: normal;

        &_selected {
            padding-bottom: 0;
            border-bottom: 2px solid #0066cc;
        }
    }

    &-Node {
        display: block;
        border-bottom: 1px solid #979797;
        padding-bottom: 1px;
        line-height: normal;
    }

    &-Spacer {
        flex: 1;
    }

    &_size_small {
        padding: 0;
        .TabsV2-Node,
        .TabsV2-TabBtn {
            font-size: 12px;
            margin-left: 12px;
            margin-right: 12px;
        }
    }
    &_size_medium {
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 10px;
        .TabsV2-Node,
        .TabsV2-TabBtn {
            margin: 5px 12px;
            font-size: 16px;
        }
    }
    &_size_large {
        padding-top: 24px;
        padding-bottom: 24px;
        .TabsV2-Node,
        .TabsV2-TabBtn {
            font-size: 20px;
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
