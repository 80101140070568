.AchievementsSelector {
  width: 100%;
  &-Group {
    margin-left: 12px;
    & > label {
      & > span {
        &:last-child {
          font-weight: bold;
        }
      }
    }
    &-Item {
      margin-left: 42px;
    }
  } 
}