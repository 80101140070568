.CommonmarkRender {
    &-Blockcode {
        overflow: auto;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 2px;
        background-color: #f8f8f8;
        margin: 5px 0;
        padding: .5em;
        text-align: left;
        vertical-align: middle;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}
