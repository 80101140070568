.CommonmarkRender {
    &-Header {
        text-overflow: ellipsis;
        overflow: hidden;

        &_h_1 {}

        &_h_2 {}

        &_h_3 {}

        &_h_4 {}

        &_h_5 {}

        &_h_6 {}
    }
}