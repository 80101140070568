@import 'src/utils-old/global.scss';

.Attachments {
  padding: 0px;
  transition-property: padding-top, padding-bottom;
  transition-duration: $TransitionDuration;
  transition-timing-function: $TransitionTimingFunction;

  &_init {
    padding: 16px 24px 0 24px;
  }
}