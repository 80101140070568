.Sortable {

  &_horizontal {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
  }

  &_horizontal.Sortable_empty {
    min-width: 30px;

    .SortableItem {
      flex: 1;
    }
  }
}