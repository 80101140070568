.unfocused:hover {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #D2E3F7;
}

.focused {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #ACCEF7;
}
