.link, .link:visited {
  color: #5e93c5;
  text-decoration: none;
}

.link:hover, .link:focus {
  color: #7eadda;
  outline: 0; /* reset for :focus */
  cursor: pointer;
}

.link:active {
  color: #4a7bab;
}
