.Spinner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  color: grey;
  line-height: 1.8;

  &-Icon {
    position: absolute;
    animation: spin 1s linear infinite;
  }

  &_noOverlay {
    position: relative;
    top: auto;
    left: auto;
    z-index: auto;
    height: auto;
    width: auto;
    background-color: transparent;
    overflow: auto;
    color: transparent;

    .Spinner-Icon {
      top: 50%;
      left: 50%;
      font-size: 20px;
      margin-top: -10px;
      margin-left: -10px;
    }
  }

  &-Text {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: 10px;
    left: 0;
    display: block;
    text-align: center;
  }

  &_overlay {
    
    .Spinner-Icon.Icon {
      font-size: inherit;
      width: 15px;
      height: 15px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      

      &:before {
        content: "\ecdd";
        font-family: "pryaniky-icons";
        display: inline-block;
        text-decoration: inherit;
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: inherit;
        margin: 0;
        opacity: 0.8;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        top: 0;
        left: 0;
        line-height: 0;
        vertical-align: middle;
      }
    }
    .Spinner-Icon {
      &_align_right.Icon {
        margin-right: 15px;
      }

      &_align_left.Icon {
        margin-left: 15px;
      }
    }
  }

  &-Icon_fontSize_16 {
    font-size: 16px;
  }

  &-Icon_fontSize_40 {
    font-size: 40px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}