@mixin entryShared {
  // padding: 7px 10px 3px 10px;
  // transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  &:active {
    background-color: #cce7ff;
  }
}

.mention {
  &,
  &:visited {
    color: #575f67;
    cursor: pointer;
    display: inline-block;
    background: #e6f3ff;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
    text-decoration: none;
  }
  &:hover,
  &:focus {
    color: #677584;
    background: #edf5fd;
    outline: 0; /* reset for :focus */
  }
  &:active {
    color: #222;
    background: #455261;
  }
}

// CSS class for suggestions component
.mentionSuggestions {
  // border: 1px solid #eee;
  margin-top: 5px;
  position: absolute;
//   min-width: 220px;
//   max-width: 440px;
  background: #fff;
  background: #fff;
  box-shadow: 3px 3px 10px -3px #000;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  z-index: 2100;
  // box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  // cursor: pointer;
  // padding-top: 8px;
  // padding-bottom: 8px;
  // z-index: 2;
  // display: flex;
  // flex-direction: column;
  // box-sizing: border-box;
  // transform: scale(0);
}

// CSS classes for an entry in the suggestions component
.mentionSuggestionsEntry {
  @include entryShared;
}

.mentionSuggestionsEntryFocused {
  @include entryShared;
  background-color: #e6f3ff;
}

.mentionSuggestionsEntryText {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.mentionSuggestionsEntryAvatar {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
