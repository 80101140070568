.SecretListItem {
    display: flex;
    padding: 24px;
    cursor: pointer;

    &_selected {
        background-color: blue;
    }

    &-Image {
        height: 56px;
        margin-right: 24px;
    }

    &-Content-Title {
        font-weight: bold;
    }

    &-Spacer {
        flex: 1;
    }

    &-BadgesList {
        display: flex;
    }

    &-Links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 12px;
        margin-left: 24px;
    }

    &-LinksTitle,
    &-BadgesTitle {
        font-weight: bold;
    }

    &-Content {
        width: 250px;
        &-Dates {
            display: flex;
            align-items: center;
            margin-left: -3px;

            & > span {
                & > button {
                    margin-right: 24px;

                    span {
                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            & > div {
                color: #999;
            }
        }

        &-Participants {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            min-width: 40px;

            & > span {
                margin-top: -8px;
                margin-right: 6px;
            }

            & > a {
                margin-right: 6px;
            }
        }
    }
}
