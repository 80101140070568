@import 'src/utils-old/global.scss';

.Select {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  background-color: var(--background-color);

  &-HelpTooltip{
      top: 0px;
      right: 0px;
      position: relative;
  }
  &-ErrorIcon:before {
    // right: 15px;
    // position: absolute;
    color: $ErrorColor;
  }
  &-Inner {
    width: 100%;
    display: flex;
    align-items: center;
    // height: 100%;
    // padding-left: 8px;
    // padding-right: 4px;
    cursor: pointer;
    padding: 10px 10px 9px 18px;
    &_disabled {
      opacity: 0.3;
    }
  }

  &-Icon {
    font-size: 16px;
    // margin-right: 8px;
    // margin-left: 12px;
    text-align: center;
    color: var(--text-muted-color);
  }

  &-Caret {
    font-size: 16px;
    margin-left: 8px;

    &:hover {
      color: #999;
    }
  }

  &_open &-Caret {
    color: #333;
  }

  &-Value,
  &-Options-Item,
  &-Placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-Placeholder {
    color: #777;
  }

  .Select-Scrollbar {
    max-height: 160px;

    &_height_auto {
      max-height: initial;
    }
  }

  &-Options {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    overflow-y: auto;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    min-width: 100%;
    max-height: 250px;
    background-color: var(--background-color);
    z-index: 10;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

    &_entering {
      animation: selectSlideDown 0.3s;
    }
    &_exiting {
      animation: selectSlideUp 0.3s;
    }

    &-Item {
      height: 40px;
      line-height: 40px;
      padding: 0 12px;

      &_default {
        font-style: italic;
        color: #777;
      }

      &:hover {
        color: var(--invariant-text-color);
        background-color: var(--main-color);
        cursor: pointer;
      }
    }
  }
}

@keyframes selectSlideDown {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 160px;
  }
}

@keyframes selectSlideUp {
  from {
    opacity: 1;
    max-height: 160px;
  }
  to {
    opacity: 0;
    max-height: 0;
  }
}
