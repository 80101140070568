.ButtonBox {
    border: 1px solid #d3d9e0;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    &:empty {
        display: none;
    }
    > * {
        display: block;

        border: 0;

        // border-bottom: 1px solid #D3D9E0;

        // padding: 10px;

        &.Button {
            text-align: center;
            border-radius: 0;
        }

        &:last-child {
        }
    }
    &_buttonRatio_normal {

    }
    &_buttonRatio_equal {
        > * {
            flex: 1;
        }
    }
    &_orientation_horizontal {
        flex-direction: row;
        white-space: nowrap;
        > * {
            // flex: 1 1;

            border-left: 1px solid #d3d9e0!important;
            &:first-child {
                border-left: 0;
            }
        }
    }
    &_orientation_vertical {
        flex-direction: column;
        > * {
            border-bottom: 1px solid #d3d9e0;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    &_size_normal {
        > * {
            &.Button {
                padding: 4px 8px;
            }
        }
    }
    &_size_small {
        > * {
            &.Button {
                padding: 0px 4px;
            }
        }
    }
    &_size_medium {
        > * {
            &.Button {
                padding: 4px 8px;
            }
        }
    }
    &_size_large {
        > * {
            &.Button {
                padding: 10px 16px;
            }
        }
    }
}
