.FieldFiles {
  margin-bottom: 6px;

  &-Label {
    font-size: 16px;

    .FieldFiles-Label {
      margin-bottom: 0 !important;
    }
  }

  & > button {
    width: fit-content;
  }

  .Attachments  {
    padding: 16px 0;
  }
}