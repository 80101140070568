@import 'src/utils-old/global.scss';

.HelpTooltip {
    &_underline {
        text-decoration: underline;
        text-decoration-color: #084ca1;
        text-decoration-style: dotted;
    }

    &_helpCursor {
        cursor: help;
    }

    &_inlineBlock {
        display: inline-block;
    }

    &-Content {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}